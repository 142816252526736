export class AppConfig {
  // public static serverUrl = 'https://172.16.0.13';
  // public static serverUrl = 'https://192.168.31.85';
  //public static serverUrl = "http://localhost:8000"; // local
  //public static serverUrl = "https://race-sims.com"; // prod
  public static serverUrl = "https://dev.race-sims.com"; // dev
  // for development:
  //public static productsPath = "img/products/";
  // for production
  public static productsPath = "storage/uploads/products/";
  public static requireAuth = true;

  /**
   * The API auth credentials for OAuth.
   */
  public static authCredentials = {
    grant_type: "password",
    client_secret: "xUSurLr3DiigfqlnQaoXd9XmvAk20kVSmAeSJani",
    client_id: 6,
    /* client_secret: "GkrxJIuodvkAYtm4WJaziw2NLS4UWqSp4jUqfooG",
    client_id: 3, */
    scope: "",
  };

  public static instagramApiToken =
    "4511461431.3c130c8.7818bf1000dc4b868ab224997a000406";

  /**
   * Sets the dataTables throughout the admin to scrollable or not
   * @type {boolean}
   */
  public static dataTableScrolling = true;

  public static maxProductLineListingLength = 60;
  /**
   * Sets the notification popup display length in milliseconds
   * @type {number}
   */
  public static notificationDisplayLength = 3000;
}
